@import 'prosemirror-view/style/prosemirror.css';
@import 'prosemirror-tables/style/tables.css';
@import '@reach/dialog/styles.css';
@import '@reach/menu-button/styles.css';
@import '@reach/tooltip/styles.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blue-400: rgb(96 165 250);
  --blue-500: rgb(59 130 246);
  --blue-600: #2563eb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-400: #cbd5e0;
  --gray-400: #9ca3af;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
}

html {
  font-family: -apple-system /* system font for Safari and Firefox */,
    Helvetica /* fallback for Chrome */;
  transition: none;
  background-color: var(--gray-800);
}

body,
html {
  height: 100vh;
}

abbr[title] {
  text-decoration: none;
}

#fb-root {
  display: none;
}

a {
  color: var(--blue-400);
  cursor: pointer;
  text-decoration: underline;
}

ul,
ol {
  padding-left: 1.5rem;
}

ol li {
  list-style-type: decimal;
}

ul li {
  list-style-type: disc;
}

ol {
  list-style: inherit;
}

h1 {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
}

h4 {
  font-weight: 800;
  line-height: 1;
}

button {
  touch-action: manipulation;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.hover\:bg-gray-850:hover,
.focus\:bg-gray-850:focus,
.bg-gray-850 {
  background-color: #232b38;
}

[data-reach-tooltip] {
  background-color: var(--gray-900);
  border-radius: 4px;
  border: none;
  color: var(--gray-200);
  font-size: 12px;
  z-index: 50;
}

[data-reach-menu] [data-reach-menu-list] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* shadow-lg */
  background-color: var(--gray-700);
  border-radius: 0.25rem;
  border: none;
  padding: 0.25rem 0;
  color: #fff;
}

[data-reach-menu] [data-reach-menu-item] {
  padding: 0.25rem 0.75rem;
}

[data-reach-menu-item][data-selected] {
  background-color: var(--gray-600);
}

[data-reach-menu] {
  z-index: 50;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.6);
  z-index: 50;
  overflow: hidden;
}

[data-reach-dialog-content] {
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  padding: 0;
  margin: 2rem auto;
  max-height: calc(100vh - 7rem);
  height: calc(100vh - 7rem);
  color: #fff;
  width: 95vw;
}

[data-reach-dialog-content].small {
  max-height: 450px;
  /* height: initial; */
}

[data-reach-dialog-content].fit {
  height: initial;
}

/* Small (sm) */
@media (min-width: 640px) {
  /* ... */
}

/* Medium (md) */
@media (min-width: 768px) {
  [data-reach-dialog-content] {
    width: 65vw;
    height: calc(100vh - 85px);
    max-height: 650px;
  }
}

/* Large (lg) */
@media (min-width: 1024px) {
  [data-reach-dialog-content] {
    width: 60vw;
    max-width: 700px;
  }

  [data-reach-dialog-content].narrow {
    width: 35vw;
  }

  [data-reach-dialog-content].wide {
    width: 70vw;
    max-width: 900px;
  }
}

/* Extra Large (xl) */
@media (min-width: 1280px) {
  /* ... */
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

@keyframes scroll {
  from {
    transform: translateX(-15%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes scroll-small {
  from {
    transform: translateX(-60%);
  }

  to {
    transform: translateX(-30%);
  }
}

/* START Mapbox overrides */
.mapboxgl-popup-content {
  background-color: var(--gray-800);
  padding: 0;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--gray-800);
}

.mapboxgl-popup-tip {
  pointer-events: none;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--gray-800);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--gray-800);
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--gray-800);
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--gray-800);
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: var(--gray-800);
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--gray-800);
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: var(--gray-800);
}

.mapboxgl-popup-close-button {
  font-size: 24px;
  line-height: 1;
  margin-right: 4px;
}

/* END Mapbox overrides */

.ProseMirror {
  outline: none;
  position: relative;
  max-width: 100%;
}

.ProseMirror ul ul li {
  list-style-type: circle;
}

.ProseMirror ul ul ul li {
  list-style-type: square;
}

.ProseMirror ol ol li {
  list-style-type: lower-alpha;
}

.ProseMirror ol ol ol li {
  list-style-type: lower-roman;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror p,
.ProseMirror blockquote {
  line-height: 1.5; /* h-6 */
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ProseMirror td > p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ProseMirror .selectedCell:after {
  background: rgba(66, 153, 225, 0.4);
}

.ProseMirror blockquote {
  background: var(--gray-700);
  padding: 8px;
  border-radius: 4px;
  border-left: 2px solid var(--gray-400);
  border-right: 2px solid var(--gray-400);
}

.ProseMirror .column-resize-handle {
  background-color: var(--blue-500);
}

.ProseMirror th {
  border-bottom: 2px solid var(--gray-400);
}

.ProseMirror tr:nth-of-type(even) {
  background-color: var(--gray-700);
}

.ProseMirror th,
.ProseMirror td {
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid var(--gray-600);
}

.ProseMirror .ProseMirror-selectednode {
  outline: none;
}

.ProseMirror-selectednode img,
.ProseMirror-selectednode iframe,
.ProseMirror-selectednode .ProseMirror-selectednode-target {
  box-shadow: 0 0 0 3px var(--blue-500);
}

.ProseMirror .empty-node::before {
  position: absolute;
  color: #aaa;
  cursor: text;
}

.ProseMirror-placeholder .empty-node:hover::before {
  color: #777;
}

.ProseMirror h1.empty-node::before {
  content: 'Title';
}

.ProseMirror-placeholder::before {
  position: absolute;
  cursor: text;
  content: 'Add a note...';
}

.ProseMirror-roll-dice {
  border-bottom: 1px dashed;
  cursor: pointer;
}
